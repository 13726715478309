import React from "react";

export const ServicesSection = () => {
  return (
    <section id="services">
      <div className="container">
        <h3>
          Let Jot Studio contribute to your business growth by helping you build
          industry standard quality project.
        </h3>
        <div className="my-4 divider-section">
          <div className="box bg-none first">
            <p>
              <span className="mr-3 font20">
                <span
                  class="iconify"
                  data-icon="uim:web-grid"
                  data-inline="false"
                ></span>
              </span>
              WEB DEVELOPMENT
            </p>
            <div className="content">
              <p className="card card-hov">Business Websites</p>
              <p className="card card-hov">E-commerce custom or CMS</p>
              <p className="card card-hov">React/Angular WebApp</p>
              <p className="card card-hov">WordPress</p>
              <p className="card card-hov">Shopify</p>
              <p className="card card-hov">Portfolios</p>
              <p className="card card-hov">Real Estate Web Projects</p>
            </div>
          </div>
          <div className="box second">
            <p>
              <span className="mr-3 font20">
                <span
                  class="iconify"
                  data-icon="ant-design:mobile-twotone"
                  data-inline="false"
                ></span>
              </span>
              MOBILE APP DEVELOPMENT
            </p>
            <div className="content">
              <p className="card card-hov">Android App Development</p>
              <p className="card card-hov">iOS App Development</p>
              <p className="card card-hov">React Native</p>
              <p className="card card-hov">Flutter</p>
            </div>
          </div>
          <div className="box third">
            <p>
              <span className="mr-3 font20">
                <span
                  class="iconify"
                  data-icon="fluent:app-folder-20-regular"
                  data-inline="false"
                ></span>
              </span>
              ON DEMAND SOLUTIONS
            </p>
            <div className="content">
              <p className="card card-hov">Healthcare Apps</p>
              <p className="card card-hov">NodeJS Development</p>
              <p className="card card-hov">Real-Estate Apps</p>
              <p className="card card-hov">Social Media Apps</p>
              <p className="card card-hov">Entertainment & Event Apps</p>
              <p className="card card-hov">Travel Apps</p>
              <p className="card card-hov">Taxi & Cab Booking Apps</p>
              <p className="card card-hov">Fitness Apps</p>
              <p className="card card-hov">Food & Restaurant Apps</p>
              <p className="card card-hov">Education & eLearning Apps</p>
            </div>
          </div>
          <div className="box fourth">
            <p>
              <span className="mr-3 font20">
                <span
                  class="iconify"
                  data-icon="fluent:app-folder-20-regular"
                  data-inline="false"
                ></span>
              </span>
              UI/UX and Graphic Designs
            </p>
            <div className="content">
              <p className="card card-hov">Strategic Visual Branding</p>
              <p className="card card-hov">Custom Illustrations</p>
              <p className="card card-hov">Social Media Graphics</p>
              <p className="card card-hov">Packaging Design</p>
              <p className="card card-hov">User-Centric Design</p>
              <p className="card card-hov">Wireframing and Prototyping</p>
              <p className="card card-hov">Responsive Design</p>
              <p className="card card-hov">Accessibility-Focused Design</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="feature-progress mb-2">
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <p>INNOVATION</p>
              <div className="bar-bg">
                <div className="bar bar-1"></div>
              </div>
            </div>
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <p>SECURITY</p>
              <div className="bar-bg">
                <div className="bar bar-2"></div>
              </div>
            </div>
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <p>CREATIVITY</p>
              <div className="bar-bg">
                <div className="bar bar-3"></div>
              </div>
            </div>
          </div>
          <div className="mt-auto">
            <a href="/contact">
              <button className="btn btn-contact">
                Contact Sales
                <div className="arrow ml-2">
                  <span
                    class="iconify"
                    data-icon="bytesize:arrow-right"
                    data-inline="false"
                  ></span>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
