import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import pattern2 from "../assets/img/bg-pattern2.svg";
import pattern1 from "../assets/img/bg-pattern1.svg";
import { Sidebar } from "../components/Sidebar";

export const Contact = () => {
  return (
    <div className="contact-wrapper">
      <section className="hidden">
        <Navbar whiteBg />
        <Sidebar whiteBg />
        <div className="p-r form-wrapper">
          <div className="row">
            <div className="col-lg-2 col-sm-12 mt-auto sm-dnone">
              <img src={pattern1} alt="" className="w-100" />
            </div>
            <div className="col-lg-8 col-12">
              <div className="container text-center">
                <iframe
                  title="Contact us"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSf5YHmTfHLUpq7Wl3bzHcIErF2ksftWCuINw7d0RZdd2Zf2LA/viewform?embedded=true"
                  width="100%"
                  height="942"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 mb-auto mt-5 sm-dnone ml-auto">
              <img src={pattern2} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 col-sm-12 text-center my-3">
              <p className="font25 font-bold text-black">We are here</p>
              <p className="font18 text-grey">
                London, UK. <br /> Abuja, Nigeria <br />
                Email:{" "}
                <a
                  href="mailto:support@thejotstudio.com"
                  className="text-inherit"
                >
                  {" "}
                  support@thejotstudio.com
                </a>
              </p>
            </div>
            <div className="col-lg-6 text-center col-sm-12 my-3">
              <p className="font25 font-bold text-black">Working Hours</p>
              <p className="font18 text-grey">
                Monday - Friday: 8:00am - 7:00pm GMT <br />
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};
