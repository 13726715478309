import React from "react";
import { Footer } from "../components/FooterV";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const Faq = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar whiteBg />
        <Navbar whiteBg />
        <div className="container">
          <h2 className={"faq-header"}>FAQ</h2>
          <p>Frequently asked questions.</p>
        </div>
      </header>
      <section className="container pb-5">
        <h4 className="font-bold">What is Jot Studio?</h4>
        <p className="mb-4">
          Jot Studio is a company that transform visions into digital realities.
          We are specialized in website, mobile application developments,
          captivating UI/UX designs, and dynamic graphic solutions. Here at Jot
          Studio, we work on a simple portfolio website to complex react native
          apps.
        </p>
        <h4 className="font-bold">
          What type of apps does Jot Studio develop?
        </h4>
        <p className="lc mb-4">
          Mobile Application <br />
          Web Application
        </p>
        <h4 className="font-bold">
          What type of website does Jot Studio develop?
        </h4>
        <p className="mb-4">
          Business Website
          <br />
          Fitness website
          <br />
          E-Commerce Custom OR CMS
          <br />
          ReactJs/Angular Web applications
          <br />
          Wordpress
          <br />
          Shopify
          <br />
          Portfolios
          <br />
          Real Estate Web Project
          <br />
          Social Media
          <br />
          e.t.c
        </p>

        <h4 className="font-bold">
          Is Jot Studio specialized in website and app maintenance?
        </h4>
        <p className="mb-4">Yes, Jot Studio maintains websites and apps.</p>
        <h4 className="font-bold">Is there is a custom project available?</h4>
        <p className="mb-4">
          Yes, the Jot Studio development team will meet your custom project
          requirements and do an outstanding job. Please contact one of our
          representatives via the "Contact Us" section or email:
          support@thejotstudio.com.
        </p>
        <h4 className="font-bold">How to contact Jot Studio?</h4>
        <p className="mb-4">
          You can contact Jot Studio via email: support@thejotstudio.com
          <br />
          In addition, you can simply go to the "Contact Us" section and submit
          your inquiry directly!
        </p>
        <h4 className="font-bold">Can I request changes to a project?</h4>
        <p className="mb-4">
          Yes, Customers will be able to see the progress of their project and
          communicate with our development team directly, while also requesting
          updates and changes.
        </p>

        <h4 className="font-bold"> How long will my project take? </h4>
        <p className="mb-4">
          Jot Studio takes each project case by case. Depending on the volume of
          the project it could take from two weeks to months. However, Jot
          Studio will give each customer an estimate of time after all the
          information needed to begin the project!
        </p>

        {/* <h4 className="font-bold">Why do I have to sign up?</h4>
        <p className="mb-4">
        Jot Studio requires every customer to set up an account using our website
          www.thejotstudio.com. Customers will be able to see the progress of their
          project and communicate with our development team directly.
        </p>
        <h4 className="font-bold">
          Can I see the progress of my project from the Jot Studio website?
        </h4>
        <p className="mb-4">
          Yes, Customers will be able to see the progress of their project and
          communicate with our development team directly.
        </p>

        <h4 className="font-bold">Can I request changes from the dashboard?</h4>
        <p className="mb-4">
          Yes, Customers will be able to see the progress of their project and
          communicate with our development team directly, while also requesting
          updates and changes. How long my projects take? <br />
          Jot Studio takes each project case by case. Depending on the volume of
          the projects it could take from two weeks to months. However, Jot Studio
          will give each customer an estimate of time after all the information
          needed to begin the project!
        </p>
        <h4 className="font-bold">Why do I need monthly packages?</h4>
        <p className="mb-4">
          Jot Studio offer monthly packages to ease up the workload from customers.
          Jot Studio understands and has the expertise to keep a website
          functioning as long as possible. Maintenance is critical for that
          reason. Jot Studio offers a different set of packages including a custom
          package to enhance each client's needs and ensure their success by
          helping them from web and app aspects.
        </p> */}
      </section>
      <Footer />
    </div>
  );
};
