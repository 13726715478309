import React from "react";
import { Link } from "react-router-dom";

const arrayManagement = [
  "Meta on-page Optimization",
  "Site Security",
  "Malware Scans & Clean-up",
  "Database Back-up",
  "Technical Support",
  "Troubleshooting",
  "Performance Scans",
  "Google Webmaster",
  "Keyword analysis",
];

const arrWebsite = [
  "E-Commerce",
  "Custom Frameworks",
  "Progresive Web App",
  "Woo Commerce",
  "Custom Graphic Work",
  "Mobile Friendly",
  "Standard Pages",
  "Social Media Integration",
  "Customized Templates",
];

const arrApp = [
  "Native Programming",
  "Cross platform Native, Flutter, React Native",
  "Google Developer Set up",
  "App store connect set up",
  "Appstore Publishing",
  "Custom Graphic Work",
  "Mobile Friendly",
  "Appstore Publishing",
  "Custom Graphic Work",
  "Mobile Friendly",
];

const ui_ux = [
  "Mobile app UX and UI design services",
  "Web design services",
  "Sketching & Wireframes",
  "Dynamic prototype",
  "Cross-platform experiences design",
  "Animation prototype",
  "Graphical Interphase",
  "2x less time spent on development",
];

export const PlanSection = () => {
  return (
    <section id="plans">
      <div className="container my-5">
        <div>
          <h3 className="font-bold mt-5 mb-2 text-white">
            Simple, transparent pricing
          </h3>
          <p>
            Contact our <a href="mailto:support@thejotstudio.com" className="text-orange">support</a> to
            get a quote.
          </p>
        </div>
        <div className="row">
          {/* Management */}
          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
            <div className="shadowCard mx-auto">
              <p className="plan-txt">--improvement</p>
            </div>
            <div className="shadowCard2 mx-auto">
              <p className="plan-txt">--maintenance</p>
            </div>
            <div className="card mx-auto">
              {/* <p className="">--frameworks</p> */}

              <h3 className="header1">--Management</h3>
              <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

              <div className="content">
                <ul>
                  {arrayManagement.map((element) => (
                    <li className={"info"}>
                      <span className="mr-2"></span>
                      {element}
                    </li>
                  ))}
                </ul>

                <Link to="/contact" className={"d-flex justify-content-center"}>
                  <button className="btn btn-price">Contact Us</button>
                </Link>
              </div>
            </div>
          </div>

          {/* Web dev */}
          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
            <div className="shadowCard mx-auto">
              <p className="plan-txt">--reactjs</p>
            </div>
            <div className="shadowCard2 mx-auto">
              <p className="plan-txt">--wordpress</p>
            </div>
            <div className="card mx-auto">
              {/* <p className="">--frameworks</p> */}

              <h3 className="header2">--Web Development</h3>
              <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

              <div className="content">
                <ul>
                  {arrWebsite.map((element) => (
                    <li className={"info"}>
                      <span className="mr-2"></span>
                      {element}
                    </li>
                  ))}
                </ul>

                <Link to="/contact" className={"d-flex justify-content-center"}>
                  <button className="btn btn-price">Contact Us</button>
                </Link>
              </div>
            </div>
          </div>

          {/* App Dev */}
          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
            <div className="shadowCard mx-auto">
              <p className="plan-txt">--flutter</p>
            </div>
            <div className="shadowCard2 mx-auto">
              <p className="plan-txt">--react native</p>
            </div>
            <div className="card mx-auto">
              {/* <p className="">--frameworks</p> */}

              <h3 className="header3">--App Development</h3>
              <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

              <div className="content">
                <ul>
                  {arrApp.map((element) => (
                    <li className={"info"}>
                      <span className="mr-2"></span>
                      {element}
                    </li>
                  ))}
                </ul>

                <Link to="/contact" className={"d-flex justify-content-center"}>
                  <button className="btn btn-price">Contact Us</button>
                </Link>
              </div>
            </div>
          </div>

          {/* UI/UI Dev */}
          <div className="col-lg-6 col-md-6 col-sm-12 my-4">
            <div className="shadowCard mx-auto">
              <p className="plan-txt">--graphics</p>
            </div>
            <div className="shadowCard2 mx-auto">
              <p className="plan-txt">--figma</p>
            </div>
            <div className="card mx-auto">
              {/* <p className="">--frameworks</p> */}

              <h3 className="header4">--UI/UX</h3>
              <div className="col-lg-12 col-md-12 col-sm-12 ruler" />

              <div className="content">
                <ul>
                  {ui_ux.map((element) => (
                    <li className={"info"}>
                      <span className="mr-2"></span>
                      {element}
                    </li>
                  ))}
                </ul>

                <Link to="/contact" className={"d-flex justify-content-center"}>
                  <button className="btn btn-price">Contact Us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
