import React from "react";
import sol1 from "../assets/img/sol_port.png";
import sol2 from "../assets/img/sol2.png";

export const Portfolio = () => {
  return (
    <section id={"portfolio"}>
      <div className={"photoshopped-boxes"}>
        <h3 className="font-bold title my-4">Some of our previous projects</h3>
        <div className="masonry">
          <div className="brick">
            <div className="brick-overlay"></div>
            <img src={sol2} alt="" className="brick-image" />
            <div className="content-details fadeIn-bottom">
              <h2 className="content-title">SobrDD</h2>
              {/* <p className="content-text">This is a short description</p> */}
            </div>
          </div>
          <div className="brick">
            <div className="brick-overlay"></div>
            <img src={sol1} alt="" className="brick-image" />
            <div className="content-details fadeIn-bottom">
              <h2 className="content-title">Wolfpack</h2>
              {/* <p className="content-text">This is a short description</p> */}
            </div>{" "}
          </div>
          {/* <div className="brick">
                  <img src={sol1} alt="" className="w-100" />
                </div>
                <div className="brick">
                  <img src={sol2} alt="" className="w-100" />
                </div> */}
        </div>
      </div>
    </section>
  );
};
