import React from "react";
import logo from "../assets/img/logo.png";

export const Footer = () => {
  return (
    <footer className="position-relative" id="footer-main">
      <div className="footer pt-lg-7">
        {/* <div className="shape-container shape-line shape-position-top shape-orientation-inverse"></div> */}
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-5 mb-5 mb-lg-0">
              <a href="/" className="mr-4">
                <img src={logo} alt="" />
              </a>
              <p className="mt-4 text-sm opacity-8 pr-lg-4">
                We are a team of entrepreneurs, comprised of skilled and
                professional individuals throughout the world.
              </p>
              <ul className="nav mt-4">
                <li className="nav-item">
                  <a
                    className="nav-link pl-0"
                    href="#!"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <span
                      class="iconify"
                      data-icon="ant-design:instagram-outlined"
                      data-inline="false"
                    ></span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link pl-0"
                    href="#!"
                    // target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="jam:facebook-circle"
                      data-inline="false"
                    ></span>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link pl-0"
                    href="#!"
                    // target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="ph:dribbble-logo"
                      data-inline="false"
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 className="mb-3">Services</h6>
              <ul className="list-unstyled">
                <li>
                  <a className={"click"} href="#plans">
                    Management
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    Web
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    Mobile
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    UI/UX Designs
                  </a>
                </li>
                <li>
                  <a className={"click"} href="#plans">
                    Graphic Designs
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 className="heading mb-3">About</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="/#tech">Our Mission</a>
                </li>
                <li>
                  <a href="/#portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="mailto:support@thejotstudio.com">Support</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 className="heading mb-3">Company</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="mailto:support@thejotstudio.com">Join us</a>
                </li>
                <li>
                  <a href="/faq">Faq</a>
                </li>

                <li>
                  <a href="/#plans">Plans</a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="solid" />

          <div className="divider divider-fade divider-dark mt-4">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-6">
                <div className="text-center text-md-left">
                  <p>
                    &copy; {new Date().getFullYear()} Jots Studio. All rights
                    reserved
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="/terms">
                      Terms
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/privacy">
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
