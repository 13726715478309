import React from "react";
import { Footer } from "../components/FooterV";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

export const Terms = () => {
  return (
    <div className="faq-wrapper">
      <header>
        <Sidebar whiteBg />
        <Navbar whiteBg />
        <div className="container">
          <h2 className={"faq-header"}>{"Terms & Conditions"}</h2>
        </div>
      </header>
      <section className="container pb-5">
        <p className="mb-4">
          {`
 These terms and conditions (the "Terms and Conditions") govern the use of www.thejotstudio.com (the "Site"). This Site is owned and operated by Jot Studio. This Site is an ecommerce website.
By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.
Intellectual Property
All content published and made available on our Site is the property of Jot Studio and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.
Age Restrictions
The minimum age to use our Site is 18 years old. By using this Site, users agree that they are over 18 years old. We do not assume any legal responsibility for false statements about age.
Accounts
When you create an account on our Site, you agree to the following:
1. You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and
2. All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.
We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.
Sale of Goods And Services
These Terms and Conditions govern the sale of goods and services available on our Site. The following goods are available on our Site:
- Website;
    Page 1 of 4

Website Terms and Conditions Page 2 of 4
- Android Apps; and
- IPhone Apps.
The following services are available on our Site:
- Website and App Development.
The services will be paid for in full 50% advance payement then 50% at completion of the project.
These Terms and Conditions apply to all the goods and services that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All information, descriptions, or images that we provide about our goods and services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all goods and services we provide. You agree to purchase goods and services from our Site at your own risk.
We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.
Subscriptions
Your subscription automatically renews and you will be automatically billed until we receive notification that you want to cancel the subscription.
To cancel your subscription, please follow these steps: The customer must notify us by writing or email about the cancellation at least 30 days before the end of the subscription.
Payments
We accept the following payment methods on our Site: - Credit Card;
   
Website Terms and Conditions     Page 3 of 4
- PayPal; and
- Debit.
When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due to this payment instrument.
If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.
Consumer Protection Law
Where the Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.
Limitation of Liability
Jot Studio and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.
Indemnity
Except where prohibited by law, by using this Site you indemnify and hold harmless Jot Studio and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
Applicable Law
These Terms and Conditions are governed by the laws of the United Kingdom.
Severability
If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these
     
Website Terms and Conditions     Page 4 of 4 Terms and Conditions. All other provisions will not be affected by the removal and the rest of these
Terms and Conditions will still be considered valid.
Changes
These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
Contact Details
Please contact us if you have any questions or concerns. Our contact details are as follows:
______________________________________ support@thejotstudio.com
Lismer Lane, London.
You can also contact us through the feedback form available on our Site.
Effective Date: ________ day of ________________, ________
  ©2002-2021 LawDepot.ca®
`}
        </p>
      </section>
      <Footer />
    </div>
  );
};
