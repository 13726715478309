import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";

export const Navbar = (props) => {
  const { whiteBg } = props;

  window.onscroll = () => scrollFunction();

  const scrollFunction = () => {
    if (window.scrollY > 150) {
      document.querySelector(".navbar")?.classList.add("fixed-top");
    } else {
      document.querySelector(".navbar")?.classList.remove("fixed-top");
    }
  };

  return (
    <div className={whiteBg ? "navbar navbarWh" : "navbar"}>
      <div className="px-4 d-flex justify-content-between align-items-center w-100">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <NavLink className="navlink" to="/" activeClassName="active">
            Home
          </NavLink>

          <a className="navlink" href="/#about">
            About
          </a>
          <a className="navlink" href="/#services">
            Services
          </a>
          <a className="navlink" href="/#plans">
            Plans
          </a>

          <NavLink className="navlink" to="/faq" activeClassName="active">
            FAQ
          </NavLink>

          <NavLink className="navlink" to="/contact">
            <button className="btn btn-contact">Contact Us</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
